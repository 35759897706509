
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/inspection";

@Options({})
export default class extends Vue {
  private active = 0;
  private refreshing1 = false;
  private loading1 = false;
  private finished1 = false;
  private refreshing2 = false;
  private loading2 = false;
  private finished2 = false;
  private recordsFirstTab: any[] = [];
  private recordsSecondTab: any[] = [];
  private previewimg = "";
  private show = false;

  protected previewimgfun(arg: any) {
    this.previewimg = arg;
    this.show = true;
  }

  protected onLoad1() {
    setTimeout(() => {
      if (this.refreshing1) {
        this.recordsFirstTab = [];
        this.refreshing1 = false;
      }
      api
        .inspectionPointHistory({
          pageNum: parseInt(this.recordsFirstTab.length / 10 + "") + 1,
          pageSize: 10,
          projectId: this.$store.state.project.projectinfo.projectId,
          type: 1,
        })
        .then((res: any) => {
          this.recordsFirstTab = [...this.recordsFirstTab, ...res.data.records];
          this.loading1 = false;
          if (this.recordsFirstTab.length >= res.data.total) {
            this.finished1 = true;
          }
        });
    }, 500);
  }

  protected onLoad2() {
    setTimeout(() => {
      if (this.refreshing2) {
        this.recordsSecondTab = [];
        this.refreshing2 = false;
      }
      api
        .inspectionPointHistory({
          pageNum: parseInt(this.recordsSecondTab.length / 10 + "") + 1,
          pageSize: 10,
          projectId: this.$store.state.project.projectinfo.projectId,
          type: 0,
        })
        .then((res: any) => {
          this.recordsSecondTab = [
            ...this.recordsSecondTab,
            ...res.data.records,
          ];
          this.loading2 = false;
          if (this.recordsSecondTab.length >= res.data.total) {
            this.finished2 = true;
          }
        });
    }, 500);
  }

  onRefresh1() {
    this.finished1 = false;
    this.loading1 = true;
    this.onLoad1();
  }

  onRefresh2() {
    this.finished2 = false;
    this.loading2 = true;
    this.onLoad2();
  }

  protected fGoAdd() {
    this.$router.push("/app/inspectionCheck");
  }
}
